
.main {
    padding: 100px;
    /* height: 100vh; */
    margin-left: 10%;
}
@media (min-width: 1024px) {
    .main {
        height: 100vh;
    }
}
@media (max-width: 1024px) {
    .main {
        padding: 50px;
        margin-left: 15%;
    }
}
@media (max-width: 768px) {
    .main {
        padding: 30px;
        margin-left: 20%;
    }
}
@media (max-width: 540px) {
    .about {
        padding: 20px;
        margin-left: 20%;
    }
}

.main__name {
    font-size: 60px;
    color: rgb(1, 66, 30);
}
@media (max-width: 768px) {
    .main__name {
        font-size: 50px;
    }
}
@media (max-width: 540px) {
    .main__name {
        font-size: 45px;
    }
}
@media (max-width: 360px) {
    .main__name {
        font-size: 40px;
    }
}

.main__intro {
    font-size: 30px;
    color: rgb(0, 0, 0);
}
@media (max-width: 768px) {
    .main__intro {
        font-size: 25px;
    }
}
@media (max-width: 540px) {
    .main__intro {
        font-size: 20px;
    }
}
@media (max-width: 360px) {
    .main__intro {
        font-size: 15px;
    }
}

.main__h {
    padding: 40px;
    font-size: 20px;
    font-style: italic;
    color: rgba(0, 0, 0, 0.7);
}
@media (max-width: 768px) {
    .main__h {
        padding: 20px;
        font-size: 25px;
    }
}
@media (max-width: 540px) {
    .main__h {
        padding: 15px;
        font-size: 20px;
    }
}
@media (max-width: 360px) {
    .main__h {
        padding: 10px;
        font-size: 15px;
    }
}

.main__contact__btn {
    border: none;
    background-color: rgb(1, 66, 30); 
    color: white;
    font-size: 25px;
    padding: 15px;
    margin-left: auto;
    margin-right: auto;
    cursor: pointer;
    border-radius: 5px;
}

.main__contact__btn:hover {
    background-color: rgb(17, 94, 51); 
}