
.about {
    padding: 100px;
    margin-left: 10%;
}
@media (max-width: 1024px) {
   .about {
       padding: 50px;
       margin-left: 15%;
   }
}
@media (max-width: 768px) {
    .about {
        padding: 30px;
        margin-left: 20%;
    }
 }

 .about__h {
    font-size: 40px;
    color: rgba(0, 0, 0, 0.7);
}
@media (max-width: 768px) {
    .about__h {
        font-size: 30px;
    }
}
@media (max-width: 540px) {
    .about__h {
        font-size: 25px;
    }
}
@media (max-width: 360px) {
    .about__h {
        font-size: 20px;
    }
}

.about__img {
    width: 50%;
    border-radius: 10px;
}

.about__p {
    font-size: 30px;
}
@media (max-width: 1024px) {
    .about__p {
        font-size: 25px;
    }
}
@media (max-width: 768px) {
    .about__p {
        font-size: 20px;
    }
}
